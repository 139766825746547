import React, { Component } from 'react';
import LinkButton from "./LinkButton"
import "../styles/tabs.scss"


const tabDataNames = ["Meblowe", "Medyczne", "Samochodowe", "Jachtowe"]

const tabDataContent = [
    "Rynek meblarski wciąż zalewa nas nowymi projektami, ludzie wciąż pragną odświeżać swoje ulubione i wygodne meble. Tapicerowanie pozwoli odświeżyć czy odnowić ulubiony mebel lub zmienić go w zupełnie inny egzemplarz.",
    "Niestety podczas intensywnego użytkowania tapicerka sprzętów medycznych ulega zniszczeniom. Doświadczenie i indywidualne podejście pozwoli odnowić zużyte powierzchnie, a także uszyć dopasowane do potrzeb wałki rehabilitacyjne.",
    "Tapicerka samochodowa podczas użytkowania ulega uszkodzeniom. Wymiana tapicerki pozwoli przywrócić jej świetność, a także odmieni całkowicie wnętrze samochodu.",
    "Jachty również potrzebują modernizacji uszkodzonej tapicerki wewnętrznej, a także idealnie dopasowanej  tapicerki zewnętrznej, która będzie chroniła wnętrza przed niekorzystnymi warunkami pogodowymi"]

const pathButton = ["oferta/tapicerstwo#meblowe", "oferta/tapicerstwo#medyczne", "oferta/tapicerstwo#samochodowe", "oferta/tapicerstwo#jachtowe"]


class Tabs extends Component {
    state = {
        tabsClick: 0

    }
    handleClick = (id) => {
        this.setState({
            tabsClick: id
        })
    }

    render() {

        const tabContent = [...tabDataContent]
        const tabsNames = tabDataNames.map((tab, index) => {
            return (
                <button
                    key={tab}
                    onClick={() => this.handleClick(index)}
                    onKeyDown={() => this.handleClick(index)}
                    className={this.state.tabsClick === index ? "active tabsName" : "tabsName"}
                >
                    {tab}
                </button>
            )
        })

        return (
            <div className="tabsContainer">
                <div className="tabsNameContainer">
                    {tabsNames}
                </div>
                <div className="tabsContentContainer">
                    {tabContent[this.state.tabsClick]}
                </div>
                <LinkButton
                    text="Zobacz więcej"
                    linkPath={pathButton[this.state.tabsClick]}
                />
            </div>
        )
    }

}



export default Tabs;