import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image";
import Tabs from "../components/Tabs"
import LinkButton from "../components/LinkButton"
import "../styles/index.scss"
import machineImage from "../images/main/machine.svg"
import tapeImage from "../images/main/tape.svg"
import clothesImage from "../images/main/clothes.svg"
import woolImage from "../images/main/wool.svg"
import logo from "../images/main/logo.svg"
import SEO from "../components/seo"

const description = "Kuśnierz, krawiec, tapicer, rymarz, kaletnik. Naprawia, czyści, poddaje renowacji, szyje nowe. Skóry naturalne i zamszowe, kożuchy i futra. Konin"

const keywords = ["kuśnierz konin", "tapicer konin"]
const IndexPage = ({ data }) => (

	<>
		<SEO
			title="Strona Główna"
			description={description}
			keywords={keywords}

		/>
		<section className="hero">
			<div className="logoMobile">
				<img src={logo} alt="" />
			</div>
			<div className="imgContainer">


				<Image fluid={data.hero.childImageSharp.fluid} />
			</div>
		</section>
		<section className="about container">

			<div className="imgContainer">
				<Image fluid={data.leather.childImageSharp.fluid} />
			</div>
			<div className="textContainer">
				<img src={machineImage} alt="maszyna do szycia" />
				<h2>O FIRMIE</h2>
				<p>Wykonujemy usługi: kuśnierskie, krawieckie, tapicerskie, rymarskie i kaletnicze. Oferujemy naprawę, renowację, przeróbki, a także szycie nowych wyrobów.</p>
				<p>Realizujemy indywidualne i nietypowe zamówienia oraz projekty klientów. Stosujemy materiały własne i powierzone przez interesariuszy. </p>
				<LinkButton linkPath={"o-firmie"} />
			</div>
		</section>

		<section className="services container">

			<div className="furriery">
				<div className="textContainer">
					<h2>KUŚNIERSTWO</h2>
					<p>Od podstaw szyjemy futra damskie i męskie, a także kurtki skórzane. Prócz szycia nowych modeli, oferujemy szeroki zakres napraw i przeróbek kożuchów, odzieży ze skór licowych, zamszowych, motocyklowych, a także futer.</p>
					<LinkButton linkPath={"oferta/kusnierstwo"} />
				</div>
				<div className="imgContainer">
					<Image fluid={data.furriery.childImageSharp.fluid} />
				</div>
			</div>

			<div className="tailoring">
				<div className="textContainer">
					<h2>KRAWIECTWO</h2>
					<p>Podczas noszenia nasze odzież się zużywa lub ulega zniszczeniom, my naprawimy, przerobimy na modne fasony oraz uszyjemy nowe według własnych wzorów lub zamówień klienta.</p>
					<LinkButton linkPath={"oferta/krawiectwo"} />
				</div>
				<div className="iconsContainer">
					<div className="iconContainer">
						<img src={tapeImage} alt="miara" />
						<p>POPRAWKI KRAWIECKIE</p>
					</div>
					<div className="iconContainer">
						<img src={clothesImage} alt="ubranie" />
						<p>NAPRAWA ODZIEŻY</p>
					</div>
					<div className="iconContainer">
						<img src={woolImage} alt="wełna" />
						<p>TEKSTYLIA DO DOMU</p>
					</div>

				</div>
			</div>

			<div className="upholsterer">
				<div className="textContainer">
					<h2>TAPICERSTWO</h2>
				</div>
				<Image fluid={data.sofa.childImageSharp.fluid} />
				<Tabs />
			</div>
			<div className="group">
				<div className="saddlery">
					<div className="textContainer">
						<h2>RYMARSTWO</h2>
					</div>
					<div className="imgContainer">
						<Image fluid={data.horse.childImageSharp.fluid} />
					</div>
					<div className="decriptionContainer">
						<ul>
							<li>Wymiana skóry i innych materiałów galanterii metalowej</li>
							<li>Naprawa, renowacja i konserwacja sprzętu jeździeckiego</li>
							<li>Przeróbki i naprawa uprzęży konnej</li>
						</ul>
					</div>
				</div>
				<div className="leatherMaking">
					<div className="textContainer">
						<h2>KALETNICTWO</h2>
					</div>
					<div className="imgContainer">
						<Image fluid={data.bag.childImageSharp.fluid} />
					</div>
					<div className="decriptionContainer">
						<ul>
							<li>Naprawa torebek, portfeli, namiotów, pasków, rękawiczek</li>
							<li>Wymiana podszewek, zamków, pasków, zapięć</li>
							<li>Dorabianie dziurek i oczek</li>
						</ul>
					</div>
				</div>

			</div>

		</section>


	</>

)


export const query = graphql`
  {
  hero: file(name: {eq: "hero"}) {
             childImageSharp {
         fluid ( maxWidth: 3000) {
           ...GatsbyImageSharpFluid_noBase64
        }
       }
    },

    leather: file(name: {eq: "leather"}) {
             childImageSharp {
                 fluid( maxWidth: 600) {
                   ...GatsbyImageSharpFluid_noBase64
                 }
               }
    },

    furriery: file(name: {eq: "furriery_relf"}) {
      childImageSharp {
          fluid( maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
	},

	sofa: file(name: {eq: "sofa"}) {
      childImageSharp {
          fluid( maxWidth: 1280) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
	},

	horse: file(name: {eq: "horse"}) {
		childImageSharp {
			fluid( maxWidth: 1280) {
			  ...GatsbyImageSharpFluid_noBase64
			}
		  }
	  },

	  bag: file(name: {eq: "bag"}) {
		childImageSharp {
			fluid( maxWidth: 1280) {
			  ...GatsbyImageSharpFluid_noBase64
			}
		  }
	  },

  }
`


export default IndexPage
